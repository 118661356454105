import { useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { ROOT_SERVICES } from '@lawnstarter/customer-modules/constants';
import { useConfirmCancellationController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ActionAlternativeOption } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useAppContext } from '@src/contexts';
import { useRouteNavigation } from '@src/hooks';

import {
  style,
  StyledButtonContainer,
  StyledContainer,
  StyledPauseOrCancelContainer,
} from './styles';

export function CancelServiceScreen() {
  const { setTitle } = useAppContext();
  const modal = useModal(ModalTemplate.INFO);
  const modalContext = useContext(ModalContext);
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId } = useParams();
  const [showPauseOrCancel, setShowPauseOrCancel] = useState(true);

  const { theme, schedule, property, canCancelMowing, supportPath } =
    useConfirmCancellationController({
      scheduleId: scheduleId as string,
      propertyId: propertyId as string,
    });

  const styles = useMemo(() => style(theme), [theme]);
  const isChangeProAllowed = useMemo(() => schedule?.service?.can_change_pro, [schedule]);
  const isLawnMowing = useMemo(
    () => schedule?.service?.str_id === ServiceType.LawnMowing,
    [schedule?.service],
  );
  const routeParams = useMemo(
    () => ({
      propertyId: property?.id,
      scheduleId: schedule?.id,
    }),
    [property, schedule],
  );
  const isRootService = useMemo(
    () => (schedule?.service?.str_id ? ROOT_SERVICES.includes(schedule.service.str_id) : false),
    [schedule],
  );
  const generatedSupportPath = useMemo(
    () =>
      generatePath(supportPath, {
        propertyId: `${propertyId}`,
        scheduleId: `${scheduleId}`,
      }),
    [supportPath, propertyId, scheduleId],
  );

  useEffect(() => {
    if (!isLawnMowing) {
      setShowPauseOrCancel(false);
    }
  }, [isLawnMowing]);

  useEffect(() => {
    const title = showPauseOrCancel ? t('pauseOrCancel.title') : t('cancelService.title');
    setTitle(title);
  }, [showPauseOrCancel, setTitle]);

  const renderBlockCancellationModal = () =>
    modal.show({
      content: (
        <>
          <Text variant="headlineSmall" style={styles.cannotCancel}>
            {t('cancelService.blockCancelation.cannotCancel')}
          </Text>
          <Text variant="bodyMedium">
            {t('cancelService.blockCancelation.please')}
            <div className="inline" style={{ color: theme.colors.blue.primary }}>
              <Link
                to={generatedSupportPath}
                onClick={() => {
                  modalContext.hide();
                }}
              >
                {` ${t('cancelService.blockCancelation.linkText')} `}
              </Link>
            </div>
            {t('cancelService.blockCancelation.help')}
          </Text>
        </>
      ),
    });

  const renderPauseOrCancel = () => (
    <>
      <DetailsHeader showBackButton />
      <StyledPauseOrCancelContainer>
        <Text variant="headlineSmall" style={styles.pauseOrCancel}>
          {t('pauseOrCancel.thanks')}
        </Text>
        <Text
          style={{ textAlign: 'center', lineHeight: theme.fonts.bodyMedium.lineHeight }}
          variant="bodyMedium"
        >
          {t('pauseOrCancel.message')}
        </Text>
      </StyledPauseOrCancelContainer>
      <StyledButtonContainer>
        <Button
          mode="contained"
          style={{ marginBottom: theme.spacing.s3 }}
          trackID="cancel_service_screen-pause_service"
          onPress={() => {
            navigate(WebRoutes.pauseService, {
              params: routeParams,
            });
          }}
        >
          {t('pauseOrCancel.pauseUntilSpring')}
        </Button>
        <Button
          trackID="cancel_service_screen-cancel_service"
          mode="contained-tonal"
          onPress={() => {
            if (canCancelMowing) {
              setShowPauseOrCancel(false);
              return;
            }

            renderBlockCancellationModal();
          }}
        >
          {t('pauseOrCancel.cancelService').toUpperCase()}
        </Button>
      </StyledButtonContainer>
    </>
  );

  if (showPauseOrCancel) {
    return renderPauseOrCancel();
  }

  return (
    <>
      <DetailsHeader showBackButton />
      <StyledContainer>
        <ActionAlternativeOption
          showDivider
          title={t('cancelService.moving.heading')}
          description={t('cancelService.moving.content')}
          target={{
            name: t('contactSupport.title').toUpperCase(),
            onPress: () => navigate(supportPath as WebRoutes, { params: routeParams }),
          }}
        />
        {isChangeProAllowed && (
          <ActionAlternativeOption
            showDivider
            title={t('cancelService.changePro.heading')}
            description={t('cancelService.changePro.content')}
            target={{
              name: t('changeProvider').toUpperCase(),
              onPress: () => navigate(WebRoutes.changeMyPro, { params: routeParams }),
            }}
          />
        )}
        <ActionAlternativeOption
          title={t('cancelService.stillCancel.heading')}
          description={t('cancelService.stillCancel.content')}
        />
        {isRootService && <Text>{t('cancelService.cancelationNote')}</Text>}
      </StyledContainer>
      <StyledButtonContainer className="flex">
        <div style={{ flex: 1, paddingRight: theme.spacing.s1 }}>
          <Button
            mode="contained-tonal"
            trackID="cancel_service_screen-close"
            onPress={() =>
              navigate(WebRoutes.serviceDetail, {
                params: routeParams,
              })
            }
          >
            {t('goBack')}
          </Button>
        </div>
        <div style={{ flex: 2, paddingLeft: theme.spacing.s1 }}>
          <Button
            mode="contained"
            trackID="cancel_service_screen-continue"
            onPress={() => navigate(WebRoutes.confirmCancel, { params: routeParams })}
          >
            {t('cancelService.submit').toUpperCase()}
          </Button>
        </div>
      </StyledButtonContainer>
    </>
  );
}
